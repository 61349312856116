/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import router from './router'
import VueKatex from "vue-katex";
import katex from "katex";
import './plugins/click-away'
import moment from 'moment'
import Axios from './Axios';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import VueFab from 'vue-float-action-button'
import VueFab from './float_dist'
import RouteManager from './router/routeManager';
import { uuid } from 'vue-uuid';

import { library} from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import JsonExcel from "vue-json-excel";
import './scss/app.scss';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { all } from '@awesome.me/kit-KIT_CODE/icons'
library.add(fas, far, fab)



Vue.use(Antd);

window.katex = katex;

Vue.use(VueKatex, {
  globalOptions: {}
});

Vue.use(VueFab, {
  // opitons 可选iconfont图标或MaterialIcons} */
  // iconType: 'MaterialDesign'
  // iconType: 'iconfont'
})

Vue.prototype.$Moment = moment
Vue.prototype.$UUID = uuid;

Vue.prototype.$AXIOS = Axios;
Vue.prototype.$BACKEND_LEGACY_URL = "https://dev.silabu.com/api/v1" //"http://localhost:3000/api/v1";
Vue.prototype.$BACKEND_URL =    "https://api.silabu.com/v1"; // "https://staging.silabu.com/v1" //   ;  "https://dev.silabu.com/v1" // 
Vue.prototype.$FRONTEND_URL =  "https://silabu.com" // "https://web-dev.silabu.com" //  "http://localhost:8080";  //  
Vue.prototype.$FILE_BASE_URL = "https://dlhmta35i3f19.cloudfront.net/" 

Vue.prototype.$RouteManager = RouteManager;

//prod-bucket = "https://dlhmta35i3f19.cloudfront.net/"
// staging-bucket =  "https://d3bfzj6ykggjjf.cloudfront.net/"
//dev-bucket = "https://d3oh23bkh3m9fb.cloudfront.net/"



Vue.config.productionTip = false

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("downloadExcel", JsonExcel);



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')